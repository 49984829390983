/* Início de resolução do problema de alinhamento do rappasoft/laravel-livewire-tables */
input.form-control {
    height: auto;
}

/* Fim de resolução do problema de alinhamento do rappasoft/laravel-livewire-tables */

.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

[x-cloak] {
    display: none !important;
}

/* Início da resolução do problema com o heigth dentro do componente Tom-Select */
div.tom-select > div.ts-control:empty {
    height: calc(2.25rem + 2px);
}
/* Fim da resolução do problema com o heigth dentro do componente Tom-Select */

.custom-responsive-table {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%;
}

/* Início do ajuste visual do conteúdo do menu lateral */
a.truncate > p {
    display: inline-block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 159px;
}

.sidebar {
    height: fit-content !important;
    overflow-x: hidden !important;
}
/* Fim do ajuste visual do conteúdo do menu lateral */

/* Início da adição de scroll vertical dentro do menu lateral */
aside {
    overflow: hidden !important;
}

aside:hover {
    overflow-y: scroll !important;
    scrollbar-width: thin;
    scrollbar-color: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Start scrollbar style for chrome */

aside::-webkit-scrollbar {
    width: 5px !important;
    height: 8px !important;
    background-color: transparent !important;
}

aside::-webkit-scrollbar-thumb {
    background: #c1c1c1 !important;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Fim do estilo de scrollbar para o chrome */
/* Fim da adição de scroll vertical dentro do menu lateral */

/* Início customização componente de upload*/

label.simaa--upload-area {
    display: inline-block;
    padding: 20px 1em;
    text-align: center;
    background-color: rgb(240, 240, 240);
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 0px !important;
}

label.simaa--upload-area.disabled {
    color: rgba(71, 71, 71, 0.8);
    cursor: not-allowed;
}

input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

/* Fim customização componente de upload*/
